import Layout from "../../layout"
import { useEffect } from "react";
import { Link } from "react-router-dom";
function SolarPanels() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout pageTitle="Solar Panels" activePage='products'>
            <main>
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Solar Panels</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="products">
                    <div className="container">
                        <div className='row'>
                            <div className='col-12 col-md-12'>
                                <h3>Discover the Future of Clean Energy<br /><br /></h3>
                                <p>Transform your home or business with our cutting-edge solar panels, designed for maximum efficiency and durability. Save on energy costs while contributing to a greener planet. Explore our top products below and start your journey towards sustainable living today.<br /><br />
                                </p>

                            </div>
                        </div>
                        <div className="row">
                            {/* Product 1 */}
                            <div className="col-12 col-md-6">
                                <div className="product-card">
                                    <div className="product-image">
                                        <img src={require('../../../assets/img/solar-1.jpg')} alt="SOMERA 525-550W 144 CELL" className="img-fluid" />
                                    </div>
                                    <div className="product-details">
                                        <h3><Link to='/products/solar-panels/somera-525-550w-144-cell' >SOMERA 525-550W 144 CELL</Link></h3>
                                        <p>
                                            <strong>Brand:</strong> Vikram Solar<br />
                                            <strong>Description:</strong> Somera, the monofacial multi busbar PV modules made with cylindrical tabbing wire to reduce the shadow on cell active area allowing the electrical charges to travel a comparatively shorter distance with less resistance leading to boost the module power helping to achieve lower cell to module conversion loss.
                                        </p>
                                        <ul className="product-features">
                                            <li>Efficiency: 21.33%</li>
                                            <li>Power Output: 525-550W</li>
                                        </ul>
                                        <p className="pt-3">
                                            <Link to='/products/solar-panels/somera-525-550w-144-cell' className="btn btn-primary">View Details</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="product-card">
                                    <div className="product-image">
                                        <img src={require('../../../assets/img/solar-2.jpg')} alt="PREXOS 525-550W 144 CELL" className="img-fluid" />
                                    </div>
                                    <div className="product-details">
                                        <h3><Link to='/products/solar-panels/somera-525-550w-144-cell' >PREXOS 525-550W 144 CELL</Link></h3>
                                        <p>
                                            <strong>Brand:</strong> Vikram Solar<br />
                                            <strong>Description:</strong> Monocrystalline Solar PV Modules, Bifacial, MBB,
                                            M10 Half-Cell. ANTI-STAINING PERFORMANCE of the backsheet ensures reduced CLEANING FREQUENCY OF REAR SIDE of the module, leading to reduction in water usage. CYLINDRICAL TABBING WIRE is used to reduce the shadow on cell active area. UP TO 15% POWER GAIN from ground facing side.
                                        </p>
                                        <ul className="product-features">
                                            <li>Efficiency: 21.33%</li>
                                            <li>Power Output: 525-550W</li>
                                        </ul>
                                        <p className="pt-3">
                                            <Link to='/products/solar-panels/prexos-525-550W-144-cell' className="btn btn-primary">View Details</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export default SolarPanels