
export default function Footer() {
    return (

        <>
            <footer id="footer" className="footer">

                <div className="container">
                    <div className="row gy-3">
                        <div className="col-lg-3 col-md-6 d-flex">
                            <i className="bi bi-geo-alt icon"></i>
                            <div>
                                <h4>Address</h4>
                                <p>
                                    Eco Global Sales Corp<br />
                                    Circular Road, Near Hathi Gate, Batala, Pin code - 143505<br />
                                    District - Gurdaspur (Punjab)
                                </p>
                            </div>

                        </div>

                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className="bi bi-telephone icon"></i>
                            <div>
                                <h4>Booking</h4>
                                <p>
                                    <strong>Phone:</strong> +91 7889210104<br />
                                    <strong>Email:</strong> service@egsolar.in<br />
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className="bi bi-clock icon"></i>
                            <div>
                                <h4>Opening Hours</h4>
                                <p>
                                    <strong>Mon-Sat: 9AM</strong> - 7PM<br />
                                    Sunday: Closed
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Follow Us</h4>
                            <div className="social-links d-flex">
                                <a href="https://www.facebook.com/egscorp" target="_blank" className="facebook"><i className="bi bi-facebook"></i></a>
                                <a href="https://instagram.com/eco_global_sales_corp?igshid=NTc4MTIwNjQ2YQ==" className="instagram" target="_blank"><i className="bi bi-instagram"></i></a>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Eco Global Sales Corp</span></strong>. All Rights Reserved
                    </div>
                    <div className="credits">


                    </div>
                </div>

            </footer>

            <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

            {/* <div id="preloader" style={{ backgroundColor: "#ccc" }}></div> */}
        </>
    )
}