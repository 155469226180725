import Layout from "../../../layout";
import { useEffect } from "react";
import { Link } from "react-router-dom";
function Somera525() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove("mobile-nav-active");
    }, []);

    return (
        <Layout pageTitle="Solar Panels" activePage="products">
            <main id="productPage">
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>SOMERA 525-550W 144 CELL Solar Panel</h2>
                                <p className="lead-text">Harness the power of the sun with Vikram Solar's high-efficiency solar panel, engineered for superior performance and durability.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="product-overview">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* Product Image */}
                            <div className="col-md-6">
                                <img src={require('../../../../assets/img/solar-1.jpg')} alt="Solar Panel 1" className="img-fluid" />
                            </div>
                            {/* Product Details */}
                            <div className="col-md-6">
                                <h3 className="product-title">SOMERA 525-550W 144 CELL</h3>
                                <p className="brand">Brand: Vikram Solar</p>
                                <ul className="product-features">
                                    <li><strong>Efficiency:</strong> 21.33%</li>
                                    <li><strong>Power Output:</strong> 525-550W</li>
                                </ul>
                                <p>
                                    Somera, the monofacial multi busbar PV modules made with cylindrical tabbing wire to reduce the shadow on cell active area allowing the electrical charges to travel a comparatively shorter distance with less resistance leading to boost the module power helping to achieve lower cell to module conversion loss.<br /><br />

                                    Modules having excellent low light & longer wavelength response
                                    Applicable across all projects having land constraint mainly in developing markets like Indian sub-continent, MENA .
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="product-features" className="bg-light py-5">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Cylindrical Tabbing Wire" className="feature-icon" />
                                <h4>Cylindrical Tabbing Wire</h4>
                                <p>Reduces shadow on the cell active area, boosting overall efficiency.</p>
                            </div>
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Low Light Performance" className="feature-icon" />
                                <h4>Excellent Low Light Performance</h4>
                                <p>Performs effectively even under low-light conditions like early morning or dusk.</p>
                            </div>
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Hotspot Reduction" className="feature-icon" />
                                <h4>Hotspot Temperature Reduction</h4>
                                <p>Minimizes heat production, extending module lifespan.</p>
                            </div>
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Reduced Power Loss" className="feature-icon" />
                                <h4>Reduced Power Loss</h4>
                                <p>Shorter electrical pathways and multiple busbars help minimize power loss.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="technical-specifications" className="py-5">
                    <div className="container">
                        <h3>Technical Specifications</h3>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Power Output (W)</td>
                                    <td>525-550W</td>
                                </tr>
                                <tr>
                                    <td>Efficiency (%)</td>
                                    <td>Up to 21.33%</td>
                                </tr>
                                <tr>
                                    <td>Module Type</td>
                                    <td>Monofacial, Multi Busbar</td>
                                </tr>
                                <tr>
                                    <td>Temperature Coefficient (Pmax)</td>
                                    <td>-0.35%/°C</td>
                                </tr>
                                <tr>
                                    <td>Operating Temperature</td>
                                    <td>-40°C to +85°C</td>
                                </tr>
                                <tr>
                                    <td>Dimensions (mm)</td>
                                    <td>2274 × 1134 × 35mm</td>
                                </tr>
                                <tr>
                                    <td>Weight</td>
                                    <td>28.2 kg</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export default Somera525;
