import Logo from '../../assets/img/logo.jpg'
import { Link } from 'react-router-dom'
export default function Header({ activePage }: any) {
    const toggeleNav = () => {
        let body = document.body;
        body.classList.toggle("mobile-nav-active")
        let menuToggle = document.querySelector(".mobile-nav-show")
        let menuClose = document.querySelector(".mobile-nav-hide")
        menuToggle?.classList.add("mobile-nav-hide")
        menuToggle?.classList.add("d-none")
        menuToggle?.classList.remove("mobile-nav-show")

        menuClose?.classList.add('mobile-nav-show')
        menuClose?.classList.remove("mobile-nav-hide")
        menuClose?.classList.remove("d-none")

    }
    return (
        <header id="header" className="header  d-flex align-items-center flex-column">
            <div className="container-fluid border-bottom">
                <div className="container d-flex align-items-center justify-content-between top-bar">
                    <div className="call-us d-flex align-items-center justify-content-start">
                        <div className="call-list-item">
                            <i className="bi bi-telephone-fill"></i> <a href="tel:7889210104">CALL: 7889210104</a>
                        </div>
                        <div className="call-list-item">
                            <i className="bi bi-envelope-fill"></i> <a href="mailto:service@egsolar.in"><span>Email: </span> <span>service@egsolar.in</span></a>
                        </div>
                    </div>
                    <Link className="btn-book-a-table" to="/contact-us">GET A QUOTE</Link>
                </div>
            </div>
            <div className="container d-flex align-items-center justify-content-between py-2">

                <div className="d-flex align-items-center me-auto me-lg-0">
                    <Link to="/" className="logo">
                        <img src={Logo} alt="" />

                    </Link>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><Link to="/" className={(activePage == 'home') ? "active" : ""}>Home</Link></li>
                        <li><Link to="/about-us" className={(activePage == 'about') ? "active" : ""}>About Us</Link></li>
                        <li><Link to="/awards" className={(activePage == 'awards') ? "active" : ""}>Awards</Link></li>
                        <li className="dropdown"><Link to="/products/solar-panels" className={(activePage == 'solar-panels') ? "active" : ""}><span>Products</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
                            <ul>
                                <li><Link to="/products/solar-panels">Solar Panels</Link></li>
                                <li><Link to="/">Solar Inverters</Link></li>
                                <li><Link to="/">Solar Batteries</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/our-projects" className={(activePage == 'projects') ? "active" : ""}>Our Projects</Link></li>
                        <li><Link to="/special-offers" className={(activePage == 'special-offers') ? "active" : ""}>Special Offers</Link></li>
                        <li><Link to="/contact-us" className={(activePage == 'contact') ? "active" : ""}>Contact Us</Link></li>

                    </ul>
                </nav>


                <i onClick={() => { toggeleNav() }} id="menu-toggle" className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                <i onClick={() => { toggeleNav() }} id="menu-close" className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

            </div>
        </header>
    )
}