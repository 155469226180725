import Layout from "../layout"
import React, { useState, useEffect } from 'react';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const projectsData = [
    {
        title: "Residential Solar Installations",
        content: "Eco Global Sales Corp has partnered with hundreds of homeowners to bring the power of solar energy to their residences. Our residential solar installations are designed to meet the unique needs of each household, providing them with efficient, reliable, and cost-effective energy solutions."
    },
    {
        title: "Commercial Solar Projects",
        content: "Our commercial solar projects are tailored to help businesses reduce operational costs and achieve their sustainability goals. Eco Global Sales Corp has worked with a diverse range of industries, providing them with scalable solar solutions that support their energy needs."
    },
    {
        title: "Community Solar Projects",
        content: "Eco Global Sales Corp is proud to contribute to community solar initiatives that make renewable energy accessible to all. Our community solar projects are designed to provide shared solar power to multiple users, reducing reliance on fossil fuels and promoting sustainable living."
    },
    {
        title: "Government and Public Sector Projects",
        content: "Eco Global Sales Corp has been a trusted partner for government agencies and public institutions in implementing solar energy projects that support public infrastructure and services. Our work in this sector is a testament to our ability to deliver high-quality, reliable, and sustainable energy solutions on a large scale."
    },
    {
        title: "Industrial Solar Projects",
        content: "Eco Global Sales Corp has successfully executed large-scale industrial solar projects, helping manufacturing plants and industrial facilities reduce their energy consumption and environmental impact."
    },
];

const images: any = [

    { src: require('../../assets/img/500kw.jpg'), caption: "500KW" },
    { src: require('../../assets/img/200kw.jpg'), caption: "200KW" },
    { src: require('../../assets/img/200kw1.jpg'), caption: "200KW" },
    { src: require('../../assets/img/75kw.jpg'), caption: "75KW" },
    { src: require('../../assets/img/project1.jpg'), caption: "" },
    { src: require('../../assets/img/project2.jpg'), caption: "" },
    { src: require('../../assets/img/project3.jpg'), caption: "" },
    { src: require('../../assets/img/project4.jpg'), caption: "" },
    { src: require('../../assets/img/200kw2.jpg'), caption: "" },
];

const ProjectSection = ({ title, content }: any) => (
    <div className="project-section">
        <p><strong>{title}</strong><br />
            {content}</p>
    </div>
);

const ImageSection = ({ images }: any) => (
    <div className="project-images">
        {images.map((image: any, index: any) => (
            <div key={index} className="image-item">
                <img src={image.src} alt={image.caption} className="img-fluid w-100 rounded-2" />
                <div className="caption">{image.caption}</div>
            </div>
        ))}
    </div>
);

function Projects() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])

    const [index, setIndex] = useState(-1);

    const handleClick = (index: any) => {
        setIndex(index);
    };


    return (
        <Layout pageTitle="Our Projects" activePage='projects'>
            <main>
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Our Projects</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="projects">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <h3 className="project-heading">Solar Projects by Eco Global Sales Corp: Pioneering Sustainable Energy Solutions<br /><br /></h3>
                                <ImageSection images={images.slice(0, 2)} />

                                {projectsData.map((project, index) => (
                                    <ProjectSection key={index} title={project.title} content={project.content} />
                                ))}


                                <ProjectSection title="Why Choose Eco Global Sales Corp for Your Solar Project?" content="Eco Global Sales Corp stands out for our comprehensive approach to solar energy. Our projects are not just about installing solar panels; they’re about creating tailored solutions that meet the specific needs of our clients while promoting a sustainable future. With a team of experienced engineers, project managers, and support staff, we ensure every project is completed on time, within budget, and to the highest standards of quality." />

                                <ImageSection images={images.slice(2, 4)} />

                                <div className="gallery">
                                    <h4 className="text-center mt-3">Project Gallery<br /><br /></h4>
                                    <Gallery images={images} onClick={handleClick}
                                        enableImageSelection={false}
                                        margin={10}
                                        tileViewportStyle={{ width: "300px", }}
                                        thumbnailStyle={{ width: '300px' }}
                                    />

                                    <Lightbox
                                        open={index >= 0}
                                        index={index}
                                        close={() => setIndex(-1)}
                                        slides={images.map((img: any) => ({ src: img.src, title: img.caption }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </Layout>
    )
}
export default Projects