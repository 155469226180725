import Layout from "../layout"
import { useEffect } from "react";

function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout pageTitle="About Us" activePage='about'>
            <main id="productPage"><div className="content-title">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About ECO GLOBAL SALES CORP: Your Partner in Solar Innovation</h2>
                            <p className="lead-text">AN ISO 9001:2000 MNRE, PEDA, PSPCL Certified Solar Company.</p>
                        </div>
                    </div>
                </div>
            </div>

                <section id="about">
                    <div className='container '>
                        <div className='row'>
                            <div className='col-12'>
                                <h3>Empowering a Sustainable Future with ECO GLOBAL SALES CORP<br /></h3>

                                <p>
                                    Welcome to ECO GLOBAL SALES CORP, your premier partner in the realm of alternate energy solutions. As a vertically integrated and end-to-end alternate energy services provider, we take pride in being a registered CLASS 1 channel partner with the state government's nodal agency. Our commitment to sustainability is reflected in our collaborations with prominent entities such as PEDA (Punjab Energy Development Agency), MNRE (Ministry of New & Renewable Energy) of the Government of INDIA, and PSPCL (Punjab State Power Corporation Limited).<br /><br />
                                </p>

                                <div className="award-image left">
                                    <img src={require('../../assets/img/dist-cert.jpg')} alt="Our Certification" className="img-fluid" />
                                </div>

                                <p><strong> Our Achievements</strong><br />
                                    Over the past four years, ECO GLOBAL SALES CORP has successfully installed more than 6MW of Solar Plants, ranging from 2KWp to 500KWp capacities. Our journey is rooted in a passion for building a sustainable future, and we strive to be at the forefront of the renewable energy movement.
                                </p>

                                <p>

                                    <strong> Core Principles</strong><br />
                                    Eco Global Sales Corp was founded on the fundamental principle of fostering a sustainable future. Our primary goal is not only to provide cutting-edge alternate energy solutions but also to educate our customers on the significance of solar energy and its implementation. We firmly believe that an informed customer is an invaluable asset to society.
                                </p>
                                <p><strong>
                                    Environmental Stewardship</strong><br />
                                    We recognize the urgency of addressing climate change, and our mission is to empower individuals and businesses to actively contribute to reducing greenhouse gas emissions. By choosing ECO GLOBAL SALES CORP, our customers play a vital role in helping reverse the adverse effects of global climate change.
                                </p>

                                <p>

                                    <strong>  Commitment to Quality</strong><br />
                                    At ECO GLOBAL SALES CORP, we set ourselves apart by delivering top-quality products crafted from the finest raw materials. Our solar solutions are meticulously designed to withstand extreme conditions, ensuring top-notch performance over the long term. We prioritize excellent workmanship to provide our customers with products that not only meet but exceed their expectations.
                                </p>

                                <p>

                                    <strong>  Network of Alliances</strong><br />
                                    Our commitment to delivering exceptional value is further strengthened by our extensive network of alliances. These partnerships enable us to procure the best equipment at competitive prices, allowing us to pass on cost savings to our valued customers. At ECO GLOBAL SALES CORP, we believe that providing affordable access to high-quality alternate energy solutions is essential in driving widespread adoption.
                                </p>
                                <p>
                                    In conclusion, ECO GLOBAL SALES CORP stands as a beacon of sustainability, innovation, and commitment to a brighter, greener future. Join us in our journey towards a world powered by clean, renewable energy.
                                </p>

                            </div>
                            {/* <div className='col-12 col-md-4'>
                                <h3>Get a Quote</h3>
                                <p>
                                    <form className="about-form ">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" className="form-control" id="name" name="name" required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email address</label>
                                            <input type="email" className="form-control" id="email" name="email" required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="phone" className="form-label">Phone</label>
                                            <input type="tel" className="form-control" id="phone" name="phone" required />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="question" className="form-label">Anything to ask</label>
                                            <textarea className="form-control" id="question" name="question" required></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </form>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </section>
            </main>
        </Layout >
    )
}
export default About