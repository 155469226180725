import Layout from "../layout"
import { useEffect } from "react";

function Awards() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout pageTitle="Awards & Recognitions" activePage='awards'>
            <main>
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Awards & Recognitions</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="about">
                    <div className='container '>
                        <div className='row'>
                            <div className='col-12 col-md-12'>
                                <h3>State Leadership Awards - Punjab 2023<br /><br /></h3>
                                <div className="award-image">
                                    <img src={require('../../assets/img/award-image.jpg')} alt="Our Awards" className="img-fluid" />
                                </div>
                                <p><strong>Solar EPC Company of the Year - Residential</strong><br />In the year 2023, Eco Global Sales Corp. achieved a significant milestone by being honored with the State Leadership Award in Punjab. This prestigious accolade was awarded in recognition of our outstanding performance and leadership in the residential solar EPC (Engineering, Procurement, and Construction) sector.
                                </p>

                                <p><strong>Award Presentation</strong><br />The award was presented to Eco Global Sales Corp. for its exceptional contributions and achievements in promoting and implementing solar energy solutions for residential buildings. This recognition underscores our commitment to sustainability, innovation, and excellence in delivering top-notch solar EPC services.
                                </p>

                                <p><strong>Organized by FirstView and Powered by Industry Leaders</strong><br />The State Leadership Awards were organized by FirstView, a reputed organization known for its insightful industry research and intelligence. The event was powered by leading industry players, Goldi Solar and Solar Quarter, both of which are renowned for their contributions to the solar energy sector.</p>

                                <p><strong>Commitment to Excellence</strong><br />Eco Global Sales Corp. has consistently demonstrated a strong commitment to excellence in the solar energy domain. Our dedicated team works tirelessly to design, procure, and construct high-quality solar energy systems tailored to meet the specific needs of our residential clients. This award is a testament to our relentless pursuit of innovation and quality in all our projects.</p>

                                <p><strong>Achievements in the Residential Sector</strong><br />The residential sector poses unique challenges and opportunities, and Eco Global Sales Corp. has excelled in addressing these through:
                                    <ul>
                                        <li>Custom-Tailored Solutions: We provide customized solar solutions that fit the unique energy requirements and architectural designs of residential properties.</li>
                                        <li>Advanced Technologies: Leveraging the latest in solar technology to ensure maximum efficiency and sustainability.</li>
                                        <li>
                                            Customer Satisfaction: Ensuring a seamless and satisfactory experience for our clients from project inception to completion and beyond.</li></ul></p>

                                <p><strong>Looking Ahead</strong><br />This award not only acknowledges our past achievements but also inspires us to continue pushing the boundaries in the solar EPC field. We remain dedicated to driving the adoption of clean energy solutions, reducing carbon footprints, and contributing to a sustainable future.</p>

                                <p><strong>Gratitude</strong><br />We extend our heartfelt gratitude to FirstView, Goldi Solar, and Solar Quarter for this honor. We also thank our clients, partners, and dedicated team members, whose trust and support have been pivotal in our journey to this prestigious recognition.</p>



                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export default Awards