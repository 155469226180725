import { Link } from "react-router-dom"
import Carousel from "../../components/carousel"
import Layout from "../layout"
import { useEffect, useState } from "react"

import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { images, CustomImage } from "./images";

const slides = images.map(({ original, width, height }) => ({
    src: original,
    width,
    height,
}));



export default function Home() {

    const [index, setIndex] = useState(-1);

    const handleClick = (index: number, item: CustomImage) => setIndex(index);
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout activePage="home">
            <Carousel items={[{
                item: <video playsInline autoPlay muted loop id="bgvid" style={{ width: "100%" }}>
                    <source src="https://egsolarimages.s3.ap-south-1.amazonaws.com/banner-1.webm" type="video/webm" />
                    <source src="https://egsolarimages.s3.ap-south-1.amazonaws.com/banner-1.mp4" type="video/mp4" />
                </video>,
                interval: 12000
            }, {
                item:
                    <img
                        // style={{ height: "624px" }}
                        className="d-block w-100"
                        src={require('../../assets/img/banner-2.jpg')}
                        alt="slider image"
                    />,
                interval: 5000
            },
            //  {
            //     item:
            //         <img
            //             // style={{ height: "624px" }}
            //             className="d-block w-100"
            //             src={require('../../assets/img/banner-3.jpg')}
            //             alt="slider image"
            //         />,
            //     interval: 5000
            // },
            {
                item:
                    <img
                        // style={{ height: "624px" }}
                        className="d-block w-100"
                        src={require('../../assets/img/banner-4.jpg')}
                        alt="slider image"
                    />,
                interval: 5000
            }
            ]} />

            <main id="main">

                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">

                            <p>About <span>Us</span></p>
                        </div>

                        <div className="row gy-4">
                            <div className="col-lg-7 position-relative about-img" data-aos="fade-up" data-aos-delay="150">
                                <div className="call-us position-absolute">
                                    <h4>Call us now</h4>
                                    <p>+91 7889210104</p>
                                </div>
                            </div>
                            <div className="col-lg-5 d-flex align-items-end" data-aos="fade-up" data-aos-delay="300">
                                <div className="content ps-0 ps-lg-5">
                                    <p className="fst-italic">
                                        ECO GLOBAL SALES CORP is a vertically integrated, end to end alternate energy services
                                        provider. We are registered CLASS 1 channel partner with state govt nodal agency.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check2-all"></i> PEDA (Punjab Energy Development Agency)</li>
                                        <li><i className="bi bi-check2-all"></i> MNRE (Ministry of New & Renewable Energy) Govt. of INDIA.</li>
                                        <li><i className="bi bi-check2-all"></i> PSPCL (Punjab State Power Corporation Limited)</li>
                                    </ul>
                                    <p>
                                        In last 4 years we have installed more than 6MW of Solar Plants consisting of 2KWp to
                                        500KWp Capacities. Eco Global Sales Corp was founded on the principle of a sustainable
                                        future. Our goal is to educate our customers in solar and its implementation and provide
                                        them the opportunity to contribute to reduce greenhouse gas emissions and help reverse
                                        global climate change. We believe that an educated customer is an asset to the society.
                                        Also we believe in delivering top quality products to its customers. Our products consist of
                                        top quality raw materials and are crafted with excellent workmanship to deliver top notch
                                        performance in extreme conditions. As a company with our network of alliances enables us
                                        to procure the best equipment at low prices for our customers
                                        <Link to="/about-us"><strong>...read more</strong></Link>
                                    </p>

                                    <div className="position-relative mt-4">
                                        <img src={require('../../assets/img/about-2.jpg')} className="img-fluid" alt="" />
                                        <a href="#" className="glightbox play-btn"></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section id="why-us" className="why-us section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">

                            <p>Special <span>Offers</span></p>
                        </div>

                        <div className="row gy-4">

                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="why-box">
                                    <h3>Our Special Offers</h3>
                                    <p>

                                        At ECO GLOBAL SALES CORP, we take pride in our commitment to making sustainable energy solutions accessible to all. As part of our dedication to promoting a greener future, we are pleased to offer exclusive discounts on our extensive range of solar products.
                                    </p>
                                    <div className="text-center">
                                        <a href="#" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 d-flex align-items-center">
                                <div className="row gy-4">

                                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                                        <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                            <i className="bi bi-clipboard-data"></i>
                                            <h4>Solar Panels</h4>
                                            <p>Unleash unprecedented savings on solar panels, redefining affordability for a sustainable future.</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                                        <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                            <i className="bi bi-gem"></i>
                                            <h4>Solar Inverters</h4>
                                            <p>Harness clean energy with our advanced inverters, ensuring optimal performance and a greener tomorrow.</p>
                                        </div>
                                    </div>

                                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                                        <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                            <i className="bi bi-inboxes"></i>
                                            <h4>Solar Batteries</h4>
                                            <p>Unlock reliable and sustainable power storage solutions for a brighter future. Store, save, and power up with our high-performance solar battery technology.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                <section id="testimonials" className="testimonials">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <p>What Are They <span>Saying About Us</span></p>
                        </div>

                        <Carousel items={[
                            {
                                item: <div className="testimonial-item py-5">
                                    <div className="row gy-4 justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="testimonial-content">
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    Professionals attitude extra ordinary service team.
                                                    out of the box management
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                                <h3>Anita Bhardwaj</h3>
                                                <h4>Customer</h4>
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-center">
                                            {/* <img src={require("../../assets/img/testimonials/testimonials-1.jpg")} className="img-fluid testimonial-img" alt="" /> */}
                                        </div>
                                    </div>
                                </div>
                            },
                            {
                                item: <div className="testimonial-item py-5">
                                    <div className="row gy-4 justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="testimonial-content">
                                                <p>
                                                    <i className="bi bi-quote quote-icon-left"></i>
                                                    Excellent services and cost effective
                                                    <i className="bi bi-quote quote-icon-right"></i>
                                                </p>
                                                <h3>BOSS Gaming</h3>
                                                <h4>Ceo &amp; Founder</h4>
                                                <div className="stars">
                                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 text-center">
                                            {/* <img src={require("../../assets/img/testimonials/testimonials-1.jpg")} className="img-fluid testimonial-img" alt="" /> */}
                                        </div>
                                    </div>
                                </div>
                            },
                        ]} />


                    </div>
                </section>


                <section id="gallery" className="gallery section-bg">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">
                            <h2>gallery</h2>
                            <p>Check <span>Our Gallery</span></p>
                        </div>

                        <div className="gallery-slider swiper align-center">
                            <Gallery
                                images={images}
                                onClick={handleClick}
                                enableImageSelection={false}
                                margin={20}
                                tileViewportStyle={{ width: "300px", }}
                                thumbnailStyle={{ width: '300px' }}
                            />
                            <Lightbox
                                slides={slides}
                                open={index >= 0}
                                index={index}
                                close={() => setIndex(-1)}
                            />
                        </div >

                    </div >
                </section >

                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-header">

                            <p>Need Help? <span>Contact Us</span></p>
                        </div>

                        <div className="mb-3">

                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13561.296726432585!2d75.2055391!3d31.8161636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391bc25912e2ac19%3A0xba98d0f6a161b26f!2sEco%20Global%20Sales%20Corp!5e0!3m2!1sen!2sin!4v1705107749542!5m2!1sen!2sin" width="100%" height="450" style={{ border: 0 }} loading="lazy" ></iframe>


                        </div>

                        <div className="row gy-4">

                            <div className="col-md-6">
                                <div className="info-item  d-flex align-items-center">
                                    <i className="icon bi bi-map flex-shrink-0"></i>
                                    <div>
                                        <h3>Our Address</h3>
                                        <p>Eco Global Sales Corp
                                            Circular Road, Near Hathi Gate, Batala, Pin code - 143505
                                            District - Gurdaspur (Punjab)</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="info-item d-flex align-items-center">
                                    <i className="icon bi bi-envelope flex-shrink-0"></i>
                                    <div>
                                        <h3>Email Us</h3>
                                        <p>service@egsolar.in</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="info-item  d-flex align-items-center">
                                    <i className="icon bi bi-telephone flex-shrink-0"></i>
                                    <div>
                                        <h3>Call Us</h3>
                                        <p>+91 7889210104</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="info-item  d-flex align-items-center">
                                    <i className="icon bi bi-share flex-shrink-0"></i>
                                    <div>
                                        <h3>Opening Hours</h3>
                                        <div><strong>Mon-Sat:</strong> 9AM - 7PM;
                                            <strong>Sunday:</strong> Closed
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <form action="forms/contact.php" method="post" role="form" className="php-email-form p-3 p-md-4">
                            <div className="row">
                                <div className="col-xl-6 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col-xl-6 form-group">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" name="message" placeholder="Message" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>

                    </div>
                </section>

            </main >


        </Layout >
    )
}
