
import { Home, About, Awards, SolarPanels, ContactUs, Projects, SpecialOffers, Somera525, Prexos525 } from '../containers'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'


const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/about-us',
        element: <About />
    },
    {
        path: '/awards',
        element: <Awards />
    },
    {
        path: '/products/solar-panels',
        element: <SolarPanels />
    },
    {
        path: '/products/solar-panels/somera-525-550w-144-cell',
        element: <Somera525 />
    },
    {
        path: '/products/solar-panels/prexos-525-550W-144-cell',
        element: <Prexos525 />
    },

    {
        path: '/our-projects',
        element: <Projects />
    },
    {
        path: '/special-offers',
        element: <SpecialOffers />
    },
    {
        path: '/contact-us',
        element: <ContactUs />
    }
])

function Root() {
    return (
        <RouterProvider router={router} />
    )
}

export default Root