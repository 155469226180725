import { useEffect, useState } from "react"
import Layout from "../layout"
import { Link } from "react-router-dom"

function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [query, setQuery] = useState('');

    const [validationError, setValidationError] = useState(false)
    const [validationErrorMessage, setValidationErrorMessage] = useState('')

    const [successMsg, setSuccessMsg] = useState(false)

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setValidationError(false)
        setValidationErrorMessage('')
        setSuccessMsg(false)

        // validations
        if (name == "") {
            setValidationError(true)
            setValidationErrorMessage('Please enter your name')
            return
        }

        if (email == "") {
            setValidationError(true)
            setValidationErrorMessage('Please enter your email')
            return
        }

        if (phone == "") {
            setValidationError(true)
            setValidationErrorMessage('Please enter your phone')
            return
        }

        if (query == "") {
            setValidationError(true)
            setValidationErrorMessage('Please enter your query')
            return
        }

        setSuccessMsg(true)

        // call api for sending email
        console.log(name, email, phone, query)

        // clear the form
        setName('')
        setEmail('')
        setPhone('')
        setQuery('')
    }


    return (
        <Layout activePage='contact'>
            <main className="page">
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Contact Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="about">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-md-6'>





                                <p className="d-grid gap-2">
                                    <strong>Location</strong>
                                </p>

                                <p><strong>Eco Global Sales Corp</strong><br />
                                    Circular Road, Near Hathi Gate, Batala, Pin code - 143505
                                    District - Gurdaspur (Punjab)</p>



                                <div className="mb-3">
                                    <iframe style={{ border: 0, width: "100%", height: "350px" }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13561.296726432585!2d75.2055391!3d31.8161636!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391bc25912e2ac19%3A0xba98d0f6a161b26f!2sEco%20Global%20Sales%20Corp!5e0!3m2!1sen!2sin!4v1705107749542!5m2!1sen!2sin" ></iframe>
                                </div>



                            </div>
                            <div className='col-12 col-md-6 px-5'>


                                <div className="row">
                                    <div className="col">
                                        <p>For any kind of query please fill the form:</p>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <form onSubmit={(e) => { handleSubmit(e) }}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="name">Name</label>
                                                <input value={name} onChange={(e) => { setName(e.target.value) }} type="text" className="form-control" id="name" aria-describedby="emailHelp" placeholder="Enter your name" />

                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="email">Email Address</label>
                                                <input value={email} onChange={(e) => { setEmail(e.target.value) }} type="text" className="form-control" id="email" placeholder="Enter your email address" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="phone">Phone</label>
                                                <input value={phone} type="text" onChange={(e) => { setPhone(e.target.value) }} className="form-control" id="phone" placeholder="Enter phone number" />
                                            </div>
                                            <div className="form-group mb-3">
                                                <label htmlFor="query">Your Query</label>
                                                <textarea value={query} onChange={(e) => { setQuery(e.target.value) }} style={{ height: "200px" }} className="form-control" id="query" placeholder="Enter your query"></textarea>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>

                                </div>






                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout >
    )
}
export default ContactUs