import { useEffect } from "react"
import Layout from "../layout"


function SpecialOffers() {
    useEffect(() => {
        window.scrollTo(0, 0)
        let body = document.body;
        body.classList.remove("mobile-nav-active")
    }, [])
    return (
        <Layout pageTitle="Special Offers" activePage='special-offers'>
            <main>
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>Special Offers</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="about">
                    <div className='container '>
                        <div className='row'>
                            <div className='col-12 col-md-12'>
                                <h3>A Special Offer is Coming Soon!<br /><br /></h3>

                                <p>Get ready to experience amazing savings and unbeatable deals on our best products. Whether you're looking for something new or eyeing that one item you've had your heart set on, now is the time to make your move!<br /><br />

                                    Stay Tuned—You won't want to miss out on this exclusive opportunity!<br /><br />


                                </p>

                                <div className="award-image coming-soon">
                                    <img src={require('../../assets/img/coming-soon.png')} alt="Our Awards" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}
export default SpecialOffers