import Layout from "../../../layout";
import { useEffect } from "react";
import { Link } from "react-router-dom";
function Prexos525() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove("mobile-nav-active");
    }, []);

    return (
        <Layout pageTitle="Solar Panels" activePage="products">
            <main id="productPage">
                <div className="content-title">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <h2>PREXOS 525-550W 144 CELL Solar Panel</h2>
                                <p className="lead-text">Monocrystalline Solar PV Modules, Bifacial, MBB,
                                    M10 Half-Cell.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="product-overview">
                    <div className="container">
                        <div className="row align-items-center">
                            {/* Product Image */}
                            <div className="col-md-6">
                                <img src={require('../../../../assets/img/solar-2.jpg')} alt="Solar Panel 1" className="img-fluid" />
                            </div>
                            {/* Product Details */}
                            <div className="col-md-6">
                                <h3 className="product-title">PREXOS 525-550W 144 CELL</h3>
                                <p className="brand">Brand: Vikram Solar</p>
                                <ul className="product-features">
                                    <li><strong>Efficiency:</strong> 21.33%</li>
                                    <li><strong>Power Output:</strong> 525-550W</li>
                                </ul>
                                <p>
                                    Monocrystalline Solar PV Modules, Bifacial, MBB,
                                    M10 Half-Cell. <br /><br />

                                    ANTI-STAINING PERFORMANCE of the backsheet ensures reduced CLEANING FREQUENCY OF REAR SIDE of the module, leading to reduction in water usage.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="product-features" className="bg-light py-5">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Cylindrical Tabbing Wire" className="feature-icon" />
                                <h4>ANTI-STAINING PERFORMANCE</h4>
                                <p>ANTI-STAINING PERFORMANCE of the backsheet ensures reduced CLEANING FREQUENCY OF REAR SIDE of the module, leading to reduction in water usage.</p>
                            </div>
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Low Light Performance" className="feature-icon" />
                                <h4>CYLINDRICAL TABBING WIRE</h4>
                                <p>CYLINDRICAL TABBING WIRE is used to reduce the
                                    shadow on cell active area.</p>
                            </div>
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Hotspot Reduction" className="feature-icon" />
                                <h4>UP TO 15% POWER GAIN</h4>
                                <p>UP TO 15% POWER GAIN from ground facing side
                                    depending upon the albedo of the ground surface</p>
                            </div>
                            <div className="col-md-3 feature-box">
                                <img src={require('../../../../assets/img/check-icon.png')} alt="Reduced Power Loss" className="feature-icon" />
                                <h4>PARTIAL SHADOW CONDITIONS</h4>
                                <p>Implementation of bypass diodes in split JB series-parallel
                                    connections enable the module to perform in PARTIAL SHADOW CONDITIONS with respect to full-cell module.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="technical-specifications" className="py-5">
                    <div className="container">
                        <h3>Technical Specifications</h3>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Power Output (W)</td>
                                    <td>525-550W</td>
                                </tr>
                                <tr>
                                    <td>Efficiency (%)</td>
                                    <td>Up to 21.33%</td>
                                </tr>
                                <tr>
                                    <td>Module Type</td>
                                    <td>Monofacial, Multi Busbar</td>
                                </tr>
                                <tr>
                                    <td>Temperature Coefficient (Pmax)</td>
                                    <td>-0.35%/°C</td>
                                </tr>
                                <tr>
                                    <td>Operating Temperature</td>
                                    <td>-40°C to +85°C</td>
                                </tr>
                                <tr>
                                    <td>Dimensions (mm)</td>
                                    <td>2274 × 1134 × 35mm</td>
                                </tr>
                                <tr>
                                    <td>Weight</td>
                                    <td>28.2 kg</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </main>
        </Layout>
    );
}

export default Prexos525;
