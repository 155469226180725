import { useState } from 'react';
import Slider from 'react-bootstrap/Carousel';

export default function Carousel({ items }: any) {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: any) => {
        setIndex(selectedIndex);
    };

    return (

        <Slider style={{ backgroundColor: "#f4f4f4" }} activeIndex={index} onSelect={handleSelect} fade>

            {items.map((el: any, key: any) => {
                return (
                    <Slider.Item key={key} interval={el.interval}>
                        {el.item}
                    </Slider.Item>
                )
            })}

        </Slider>
    )
}